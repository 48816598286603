// 引入并暴露
// 先引入再暴露
// 可以理解为先引入到这个文件，再分别暴露出去，但是下面这个写法是错的，
// 可以这么理解但是不能这么写
// import trademark from "./trademark";  简写
// import {default as trademark} from './trademark'  //全写
// export trademark

import request from "@/utils/request";

// 查询列表
export function DeliveryData(query) {
  return request({
    url: "/hair/get/order",
    method: "get",
    params: query,
  });
}
