<template>
	<dir class="Container">
		<div>
			<img class="logoimg" src="../assets/expressinquire.png" alt="" />
		</div>
		<div class="body">
			<div class="smallbody">
				<div>
					<div>
						<!-- <div class="title">请输入平台订单号</div> -->
						<van-divider />
						<van-field style="font-weight: bold" v-model="queryform.orderSn" placeholder="请输入平台订单号" />
					</div>
				</div>
			</div>
			<div class="buttonContainer">
				<van-button round color="#b74a79" size="large" style="width: 80%" @click="GetDeliveryData">立 即 查 询</van-button>
			</div>
			<div v-if="orderNo">
				<div class="info mt20">快递公司:未查询到</div>
				<div class="info mt20">快递单号:未查询到</div>
			</div>
			<!-- <div v-else>
				<div class="info mt20">快递公司:{{ (infoForm[0] || []).express_company_name || '待查询' }}</div>
				<div class="info mt20" @click="link">
					快递单号:

					<div v-if="infoFormexpress_no" class="wlClass">
						{{ infoFormexpress_no }}
					</div>
					<span v-else>
						{{ '待查询' }}
					</span>
				</div>
			</div> -->
			<div v-else v-for="(item, index) in infoForm" :key="item.id" class="mt20">
				<h2 class="ml20">包裹{{ index + 1 }}</h2>
				<div class="info">快递公司: {{ item.express_company_name }}</div>
				<div class="info">
					快递单号:
					<span class="wlClass" @click="link(item)">{{ item.express_no }}</span>
				</div>
			</div>
		</div>
	</dir>
</template>

<script>
import { areaList } from '@vant/area-data';
import { DeliveryData } from '@/api/index';
export default {
	data() {
		return {
			/* 地区列表 */
			areaList,
			show: false,
			/* 省市区回显 */
			chooseValue: '',
			/*详细地址 */
			getResult: '',
			// 省市区参数
			queryform: {
				orderSn: ''
			},
			infoForm: [],
			orderNo: false,
			infoFormexpress_no: ''
		};
	},
	methods: {
		// 跳转
		link(item) {
			location.href = 'https://www.baidu.com/s?wd=' + item.express_no;
		},

		// 发送请求
		async GetDeliveryData() {
			if (this.queryform.orderSn && this.queryform.orderSn.length > 16) {
				const result = await DeliveryData(this.queryform);
				if (result.code == 10000) {
					this.infoForm = [];
					if (result.data.orderList === null) {
						this.infoForm = [];
						this.orderNo = true;
						return;
					}
					this.orderNo = false;
					const arr = JSON.parse(result.data.orderList).filter((item, index, self) => {
						return index === self.findIndex((t) => t.express_no === item.express_no);
					});

					this.infoForm = arr;
				}
			} else {
				this.infoForm = [];
				this.$toast({
					message: '请输入正确的订单号',
					position: 'top'
				});
			}
		}
	}
};
</script>

<style>
.wlClass {
	text-decoration: underline;
	color: blue;
}
.mt20 {
	margin-top: 20px;
}
.ml20 {
	margin-left: 20px;
}
.Container {
	margin-left: -40px;
}
.Container .logoimg {
	width: 100%;
}
.Container .body {
	/* margin: 30px 0 0 30px; */
	margin-top: 30px;
	height: 50vh;
	/* background-color: pink; */
}
.Container .body .smallbody {
	display: flex;
	/* flex-direction: column; */
	/* align-items: center; */
	justify-content: center;
	margin-left: 10px;

	/* height: 50vh; */
}
.Container .body .buttonContainer {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
.Container .body .info {
	display: flex;
	justify-content: start;
	font-size: 16px;
	margin-left: 50px;
}
.title {
	font-size: 14px;
	font-weight: bold;
	padding-left: 16px;
}
</style>
