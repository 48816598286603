import VueRouter from "vue-router";
import Home from '../pages/MyHome.vue'
import News from '../pages/News.vue'

// 创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {
            path:'',
            component:Home
        },
        {
            path:'/news',
            name:"news",
            component:News
        },
        // {
        //     path:'',
        //     component:Home,
        //     // 二级路由编写
        //     children:[
        //         {   
        //         	// name 有点像路径别名，在多级路由下很有优势
        // 			// 表现在不用写一长串从父到子的路由地址
        // 			// 注意：写了name后依旧得写path
        // 			name:'news',
        //             // 二级路由的path不需要带 '/'
        //             path:'news',
        //             component:News
        //         }
        //     ]
        // }
    ]
})
